import { AES, enc } from 'crypto-js';

export const setItem = (type, key, value) => {
  try {
    type.setItem(key, AES.encrypt(value, process.env.REACT_APP_SECRET).toString());
    return true;
  } catch (error) {
    return false;
  }
};

export const getItem = (type, key, defaultValue) => {
  try {
    const encryptedValue = type.getItem(key);
    if (!encryptedValue) {
      return defaultValue;
    }
    const decryptedValue = AES.decrypt(encryptedValue, process.env.REACT_APP_SECRET).toString(enc.Utf8);
    return decryptedValue;
  } catch (error) {
    return defaultValue;
  }
};

export const removeItem = (type, key) => {
  type.removeItem(key);
};

export const removeAll = (type) => {
  type.clear();
};

export const getFirstLetter = (name) => {
  if (!name) return false;

  return name.charAt(0).toUpperCase();
};

export const toTitleCase = (str) => str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());

export const convertToPositiveNumber = (nmbr) => {
  if (nmbr < 0) {
    nmbr *= -1;
  }

  return nmbr;
};
