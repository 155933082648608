import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { getItem } from '../utils/Helper';
import { isValidToken } from '../utils/Jwt';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const token = getItem(localStorage, 'at');

  if (isValidToken(token)) {
    return <Navigate to="/dashboard/app" />;
  }

  return <>{children}</>;
}
