// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'income & expense',
    path: '/dashboard/income-expense',
    icon: getIcon('fa6-solid:money-bill-1'),
  },
  {
    title: 'owed & debt',
    path: '/dashboard/persons',
    icon: getIcon('eva:people-fill'),
  },
  // {
  //   title: 'my projects',
  //   path: '/dashboard/my-projects',
  //   icon: getIcon('eva:folder-fill'),
  // },
  {
    title: 'my categories',
    path: '/dashboard/categories',
    icon: getIcon('eva:layers-fill'),
  },
];

export default navConfig;
