import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Loading from './components/Loading';

const queryClient = new QueryClient();

export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <NotistackProvider>
            <ScrollToTop />
            <BaseOptionChartStyle />
            <Router />
            <ToastContainer />
          </NotistackProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  );
}
