import Accordion from './Accordion';
import Alert from './Alert';
import Card from './Card';
import Dialog from './Dialog';
import Paper from './Paper';
import Table from './Table';
import Select from './Select';
import Switch from './Switch';
import Chip from './Chip';
import Pagination from './Pagination';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Backdrop from './Backdrop';
import Typography from './Typography';
import CssBaseline from './CssBaseline';
import Autocomplete from './Autocomplete';
import Tabs from './Tabs';
import DataGrid from './DataGrid';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    DataGrid(theme),
    Tabs(theme),
    Pagination(theme),
    Accordion(theme),
    Chip(theme),
    Select(theme),
    Switch(theme),
    Table(theme),
    Dialog(theme),
    Alert(theme),
    Card(theme),
    Input(theme),
    Paper(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    CssBaseline(theme),
    Autocomplete(theme)
  );
}
